import React from 'react';

export default () => {

    window.location.replace("https://shpeucfbbq.webflow.io/");

    return (
        <div>
        </div>
    );
};